<template>
  <div>
    <Card :parent_id="parent_id">
      <p class="ti">兴趣爱好</p>
    </Card>
    <!--  -->
    <div class="content">
      <p>喜欢的一道菜</p>
      <el-input
        v-model="favoriteDishes"
        maxlength="20"
        placeholder="一个菜名，说一道最符合你口味的菜？"
      ></el-input>
      <p class="f12" v-if="favoriteDishesAppStatus === '2'">审核中</p>
    </div>

    <div class="content">
      <p>欣赏的一个名人</p>
      <el-input
        v-model="favoriteCelebrities"
        maxlength="20"
        placeholder="一个名字，最让你佩服的名人是谁？"
      ></el-input>
      <p class="f12" v-if="favoriteCelebritiesAppStatus === '2'">审核中</p>
    </div>

    <div class="content">
      <p>喜欢的一首歌</p>
      <el-input
        v-model="favoriteSongs"
        maxlength="20"
        placeholder="一首歌名，哪首歌最让你感动？"
      ></el-input>
      <p class="f12" v-if="favoriteSongsAppStatus === '2'">审核中</p>
    </div>

    <div class="content">
      <p>喜欢的一本书</p>
      <el-input
        v-model="favoriteBooks"
        maxlength="20"
        placeholder="一本书名，让你印象最深的是哪一本？"
      ></el-input>
      <p class="f12" v-if="favoriteBooksAppStatus === '2'">审核中</p>
    </div>

    <div class="content">
      <p>喜欢做的事</p>
      <el-input
        v-model="likeDoing"
        maxlength="20"
        placeholder="还有什么是你喜欢的？"
      ></el-input>
      <p class="f12" v-if="likeDoingAppStatus === '2'">审核中</p>
    </div>

    <!-- 按钮 -->
    <Btn style="margin-top: 56px">
      <div class="continue" @click="bcjx">保存并继续</div>
      <div class="skip" @click="tg">跳过</div>
    </Btn>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Btn from "@/components/Card/Btn.vue";
import local from "@/api/common/local.js";
import { infocur } from "@/api/login/login.js"; //updatesub //infosub,
import { allAppStatus } from "@/api/member/member.js"; //infoefresh
import { subTk, approveUpdate } from "@/api/member/member.js";
export default {
  data() {
    return {
      parent_id: "", //zujian
      favoriteDishes: "",
      favoriteCelebrities: "",
      favoriteSongs: "",
      favoriteBooks: "",
      likeDoing: "",
      favoriteBooksAppStatus: "3",
      favoriteCelebritiesAppStatus: "3",
      favoriteDishesAppStatus: "3",
      favoriteSongsAppStatus: "3",
      likeDoingAppStatus: "3",
    };
  },
  components: {
    Card,
    Btn,
  },
  created() {
    this.cs();
    this.tkL();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async cs() {
      // let a = local.get("access_token");
      // const { code, data } = await infosub(a);
      // if (code == 0) {
      //   // introduce
      //   // console.log("成功", data);
      //   this.favoriteDishes = data.favoriteDishes;
      //   this.favoriteCelebrities = data.favoriteCelebrities;
      //   this.favoriteSongs = data.favoriteSongs;
      //   this.favoriteBooks = data.favoriteBooks;
      //   this.likeDoing = data.likeDoing;
      //   this.parent_id = data.memberId;
      // }

      const { code, data } = await infocur(local.get("access_token"));
      if (code == 0) {
        // if(data.user.favoriteDishes){
        this.favoriteDishes = data.user.favoriteDishes || "";
        this.favoriteCelebrities = data.user.favoriteCelebrities || "";
        this.favoriteSongs = data.user.favoriteSongs || "";
        this.favoriteBooks = data.user.favoriteBooks || "";
        this.likeDoing = data.user.likeDoing || "";
        this.parent_id = data.user.memberId || "";
        const D = await allAppStatus();
        if (D.code == 0) {
          this.favoriteBooksAppStatus = D.data.favoriteBooksAppStatus;
          this.favoriteCelebritiesAppStatus =
            D.data.favoriteCelebritiesAppStatus;
          this.favoriteDishesAppStatus = D.data.favoriteDishesAppStatus;
          this.favoriteSongsAppStatus = D.data.favoriteSongsAppStatus;
          this.likeDoingAppStatus = D.data.likeDoingAppStatus;
        }
        // }
      }
    },
    async bcjx() {
      //保存调接口

      let a = {
        // introduce: this.rise,
        likeDoing: this.likeDoing,
        favoriteBooks: this.favoriteBooks,
        favoriteSongs: this.favoriteSongs,
        favoriteCelebrities: this.favoriteCelebrities,
        favoriteDishes: this.favoriteDishes,
      };
      const { code } = await approveUpdate(a); //msg
      if (code == 0) {
        this.$message({
          message: "修改成功",
          type: "success",
          offset: 100,
        });
        const { code, data } = await subTk();
        if (code == 0) {
          local.SessionSet("sub_tk", data);
        }
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.$router.push({
            path: `/n/user/_spousePreference`,
          });
          //延迟时间：3秒
        }, 0);
      } else {
        this.$message({
          // message: msg,
          message: "至少填写一个兴趣爱好",
          type: "error",
          offset: 100,
        });
        this.tkL();
      }
    },
    tg() {
      this.$router.push({
        path: `/n/user/_spousePreference`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 880px;
  margin-top: 16px;
  /deep/ .el-input__inner {
    width: 270px;
    color: #333333;
    border: none;
    background-color: #f5f5f5;
  }
  p {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .f12 {
    font-size: 12px;
    color: salmon;
    margin-bottom: 0px;
    margin-top: 5px;
  }
}
</style>